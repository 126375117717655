/*tblcommon*/
.tblcommon-01 table .aLeft,
.tblcommon-02 table .aLeft {
  text-align: left;
}
.tblcommon-01 table .aRight,
.tblcommon-02 table .aRight {
  text-align: right;
}

.tblcommon-01 {
  width: 100%;
}
.tblcommon-01 table {
}
.tblcommon-01 table th {
  background: #fbfbfc;
  border-top: solid 1px #e9ecf4;
  border-right: solid 1px #e9ecf4;
  box-shadow: 0px 3px 2px rgba(0, 0, 0, 0.03);
  -webkit-box-shadow: 0px 3px 2px rgba(0, 0, 0, 0.03);
  -moz-box-shadow: 0px 3px 2px rgba(0, 0, 0, 0.03);
  -ms-box-shadow: 0px 3px 2px rgba(0, 0, 0, 0.03);
  position: relative;
  padding: 8px 8px 7px;
  height: 32px;
  font-size: 14px;
  text-align: center;
  transform: skew(-0.04deg);
  -webkit-transform: skew(-0.04deg);
  -moz-transform: skew(-0.04deg);
  -ms-transform: skew(-0.04deg);
}

.tblcommon-01 table tr:first-of-type th:first-of-type {
  border-left: 0;
}
.tblcommon-01 table tr:first-of-type th:last-of-type,
.tblcommon-01 table tr:last-of-type th:last-of-type {
  border-right: 0;
}
.tblcommon-01 table tr:hover,
.tblcommon-01 table tr.on {
  background: rgba(175, 236, 255, 0.16);
  cursor: default;
}
.tblcommon-01 table tbody th {
  border-top: 0;
}
.tblcommon-01.nothead {
  border-top: solid 1px #e9ecf4;
}

.tblcommon-01 table td {
  padding: 8px 8px 7px;
  height: 34px;
  font-size: 15px;
  border-bottom: solid 1px #e9ecf4;
  /*text-overflow: ellipsis;*/
  word-break: break-all;
  white-space: nowrap;
  overflow: hidden;
  text-align: center;
  transform: skew(-0.04deg);
  -webkit-transform: skew(-0.04deg);
  -moz-transform: skew(-0.04deg);
  -ms-transform: skew(-0.04deg);
}
.tblcommon-01 table td.nodata {
  padding: 30px 0;
  color: #caccd7;
}
.tblcommon-01 table td .error {
  color: #ec2a1e;
}
.tblcommon-01 table td .stock {
  font-size: 13px;
  margin-left: 4px;
}
.tblcommon-01 table th .limit {
  display: inline-block;
  margin-top: -28px;
  height: 30%;
}
.tblcommon-01 table th .limit::after {
  content: '';
  position: absolute;
  left: 50%;
  bottom: 12px;
  transform: translateX(-50%);
  display: block;
  width: 16px;
  height: 20px;
  margin-top: -5.5px;
  background: url(../../../../../styles/images/common/sprite/sp_common.png)
    no-repeat;
  background-size: 400px auto;
  background-position: -83px -201px;
}
.tblcommon-01 table th .limit.up::after {
  background-position: -102px -201px;
}
.tblcommon-01 table th .limit.down::after {
  background-position: -121px -201px;
}

.tblcommon-01 table tr.reason_on {
  background: rgba(175, 236, 255, 0.16);
  cursor: default;
}
.tblcommon-01 table tr.reason_on td {
  text-align: left;
  padding: 30px 50px;
  border: 1px solid rgba(175, 236, 255, 0.6);
  border-radius: 6px;
}
.tblcommon-01 table tr.reason_on td p {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
}

/*result*/
.tblcommon-01 table tbody th {
  background-color: rgb(233, 236, 244, 0.3);
  box-shadow: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  -ms-box-shadow: none;
  border-right: solid 1px #e9ecf4 !important;
  border-bottom: solid 1px #e9ecf4 !important;
}
.tblcommon-01 table tbody td span.vote_check {
  display: inline-block;
  width: 114px;
  padding: 0 4px;
  text-align: center;
  color: #9eb3cd;
}
.tblcommon-01 table tbody td span.yes {
  color: #0075ff;
  font-size: 16px;
  font-weight: 800;
}
.tblcommon-01 table tbody td span.no {
  color: #ec2a1e;
  font-size: 16px;
  font-weight: 800;
}
.tblcommon-01 table tbody td span.off {
  color: #24995a;
  font-size: 16px;
  font-weight: 800;
}
.tblcommon-01 table tbody td p.vote_stock {
  margin: 5px auto;
  width: 220px;
  text-align: left;
  font-weight: bold;
  box-sizing: border-box;
  position: relative;
}
.tblcommon-01 table tbody td p.vote_stock span {
  display: inline-block;
  width: 140px;
  position: absolute;
  right: 0;
  text-align: right;
  color: #0075ff;
  font-weight: 800;
}
.tblcommon-01 table tbody td span.vote_check strong {
  display: block;
  margin-top: 4px;
}
.tblcommon-01 table td.nRight {
  text-align: right;
  padding-right: 45px;
}
.tblcommon-01 .select_ty {
  width: 90%;
}

.tblcommon-01 .vote {
  position: relative;
  display: inline-block;
  margin: 4px 2px 2px;
  font-size: 17px;
  font-weight: bold;
  width: 140px;
  text-align: center;
  padding: 8px 20px;
  background-color: #fff;
  border-radius: 4px;
  border: 2px solid #0094ff;
  box-sizing: border-box;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.18);
  -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.18);
  -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.18);
  -ms-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.18);
}
.tblcommon-01 .vote:hover,
.tblcommon-01 .vote.vote_on {
  background-color: #0075ff;
  border-color: #0075ff;
  color: #fff;
}
.tblcommon-01 .vote.check::after {
  content: '';
  position: absolute;
  top: 9px;
  left: 16px;
  display: block;
  width: 20px;
  height: 15px;
  background: url(../../../../../styles/images/common/sprite/sp_common.png)
    no-repeat;
  background-size: 340px auto;
  background-position: -256px -80px;
}
.tblcommon-01 .tblbtn_area .vote.vote_on {
  text-align: right;
}
.tblcommon-01 .stock {
  display: inline-block;
}
.tblcommon-01 .stock strong {
  display: inline-block;
  width: 120px;
  text-align: right;
  color: #0094ff;
}
.tblcommon-01 table th .stock {
  font-size: 13px;
  margin-left: 4px;
}
.tblcommon-01 table thead th.title {
  padding: 8px 280px 7px 20px;
  text-align: left;
  font-size: 18px;
  border-bottom: solid 1px #ccd5e0;
  position: relative;
}
.tblcommon-01 table thead th.title.atv {
  border-top: solid 1px #ccd5e0;
}
.tblcommon-01 table thead th .stock {
  position: absolute;
  top: 50%;
  margin-top: -7px;
  right: 20px;
  font-size: 15px;
  margin-left: 0;
}
.tblcommon-01 table tr.div th {
  border-top: solid 1px #ccd5e0;
}
